// Import React
import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from "gatsby"

/**
 * Breadcrumb Component
 */

const Breadcrumb = ({ current }) => {
  const [parents, setParents] = useState([]);
  const data = useStaticQuery(graphql`
    query BreadcrumbQuery {
      allPrismicPage {
        edges {
          node {
            id
            uid
            url
            data {
              page_parent {
                id
                uid
                url
              }
              seo_title
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    let isTop = false;
    let value = current;
    let temp = [];

    const getBreadcrumb = (node) => {
      if (node.uid === value) {
        value !== current && temp.push({ url: node.url, text: node.data.seo_title });
        value = node.data.page_parent.uid;

        if (!node.data.page_parent.uid) {
          temp.push({ url: '/', text: 'Home' });
          isTop = true;
        }
      }
    }

    while (!isTop) {
      data.allPrismicPage.edges.forEach(item => {
        const node = item.node;
        getBreadcrumb(node);
      })
    }
    setParents(temp.reverse())
  }, [data, current]);

  return (
    <div id="breadcrumb">
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {parents.map((parent, index) => {
              return (
                <li
                  key={index}
                  className="breadcrumb-item"
                >
                  <a href={parent.url}>{parent.text}</a>
                </li>
              );
            })}
            <li className="breadcrumb-item active" aria-current="page">
              {current}
            </li>
          </ol>
        </nav>
      </div>
    </div>
  );
}

export default Breadcrumb;
