import * as React from 'react';
import { PrismicRichText } from '@prismicio/react';

const Associations = ({ slice }) => {
  return (
    <div id="associations" className="associations">
      <div className="container associations-container">
        <div className="row associations-row">
          <div className="associations-body">
            <h2 className="display-6">{slice.primary.pa_headline}</h2>
            <div className="divider divider-small my-4"></div>
            <PrismicRichText field={slice.primary.pa_body_text.raw} />
          </div>
          <div className="row associations-logos">
            {slice.items.map((item, idx) => (
              <div key={idx} className="association-logo">
                <img
                  src={item.pa_association_logo.url}
                  alt={item.pa_association_name}
                  height="48"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Associations;
