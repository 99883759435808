// Import React
import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

/**
 * Breadcrumb Component
 */

const BenefitBreadcrumb = ({ uid }) => {
  const [parents, setParents] = useState([]);
  const [current, setCurrent] = useState('');
  const { allPrismicBenefit } = useStaticQuery(graphql`
    query BenefitBreadcrumbQuery {
      allPrismicBenefit {
        edges {
          node {
            id
            uid
            data {
              benefit_type
              benefit_name
              benefit_provider {
                uid
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const { node } = allPrismicBenefit.edges.find(
      (item) => item.node.uid === uid
    );
    const benefitType = node.data.benefit_type;
    const proivder = node.data.benefit_provider.uid;
    const results = [
      { url: '/', text: 'Home' },
      {
        url: `/benefits/${benefitType}`,
        text: `${
          benefitType.charAt(0).toUpperCase() + benefitType.slice(1)
        } Benefits`
      },
      {
        url: `/benefits/${proivder}`,
        text: `${proivder.charAt(0).toUpperCase() + proivder.slice(1)}`
      }
    ];

    setParents(results);
    setCurrent(node.data.benefit_name);
  }, [allPrismicBenefit, uid]);

  return (
    <div id="breadcrumb">
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {parents.map((parent, index) => {
              return (
                <li key={index} className="breadcrumb-item">
                  <a href={parent.url}>{parent.text}</a>
                </li>
              );
            })}
            <li className="breadcrumb-item active" aria-current="page">
              {current}
            </li>
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default BenefitBreadcrumb;
