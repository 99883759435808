import * as React from 'react';
import { useLayoutEffect } from 'react';
import { useRef } from 'react';

const HomeSwitch = ({ slice }) => {
  //console.log("HomeSwitch",slice)
  const elRef = useRef();

  useLayoutEffect(() => {
    if (elRef.current) {
    }
  });

  const handleClick = (category) => {
    const cookie = document.cookie;
    if (cookie.includes('news_prefrerence')) {
      return;
    } else {
      // add current post catgory to cookie
      const date = new Date();
      date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
      const expires = '; expires=' + date.toUTCString();
      document.cookie = 'news_prefrerence=' + category + expires + '; path=/';
    }
  };

  return (
    <React.Fragment>
      {slice.primary.builder_section_accent && ( <div className="container">
        <div className="row">
          <div className="col text-center p-6">
            <a
              href="https://go.sdpeba.org/cityOE/"
              className="btn btn-secondary"
              style={{ fontSize: '1.7rem' }}
            >
              Active Open Enrollment
            </a>
          </div>
          <div className="col text-center p-6">
            <a
              href="https://go.sdpeba.org/retireeOE/"
              className="btn btn-secondary"
              style={{ fontSize: '1.7rem' }}
            >
              Retiree Open Enrollment
            </a>
          </div>
        </div>
      </div> )}
      

      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12 p-4">
            <h1 className="text-center text-capitalize">
              ARTICLES & INFORMATION
            </h1>
          </div>
          <div className="col-12 col-lg-6 p-4">
            <a
              href="/archive/news/city"
              onClick={() => handleClick('city')}
              style={{
                textDecoration: 'none',
                height: '400px',
                display: 'block'
              }}
            >
              <div
                className="card"
                style={{
                  backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.7),rgba(0,0,0,0.7)), url(https://prismic-io.s3.amazonaws.com/sdpeba/e451cd09-b75a-4940-a51f-cd0bc84d982c_q1jvj7_t20_LAJLJV.jpg)`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',

                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  cursor: 'pointer'
                }}
              >
                <h1>City Employees</h1>
              </div>
            </a>
          </div>
          <div className="col-12 col-lg-6 p-4">
            <a
              href="/archive/news/county"
              onClick={() => handleClick('county')}
              style={{
                textDecoration: 'none',
                height: '400px',
                display: 'block'
              }}
            >
              <div
                className="card"
                style={{
                  backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.7),rgba(0,0,0,0.7)), url(https://prismic-io.s3.amazonaws.com/sdpeba/e451cd09-b75a-4940-a51f-cd0bc84d982c_q1jvj7_t20_LAJLJV.jpg)`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '400px !important',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  cursor: 'pointer'
                }}
              >
                <h1>County Employees</h1>
              </div>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeSwitch;
