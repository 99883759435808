// Import React
import React from 'react';
// Import React HTML Parser
import parse from 'html-react-parser';
// Import GraphQL and its Hook for Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Import the company logo
import logo from '../images/Explorations.png';

import { PrismicLink } from '@prismicio/react';

/**
 * Page Footer Component
 */

const PageFooter = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allPrismicNavigation {
        edges {
          node {
            id
            uid
            data {
              body {
                ... on PrismicNavigationBodySingleNavLink {
                  id
                  primary {
                    single_nav_link_text
                    single_nav_link_title
                    single_nav_link_url {
                      url
                      link_type
                      target
                    }
                  }
                  slice_label
                  slice_type
                }
                ... on PrismicNavigationBodyDropdownNavLink {
                  id
                  items {
                    dd_group_link_text
                    dd_group_link_title
                    dd_group_link_url {
                      url
                      link_type
                      target
                    }
                  }
                  primary {
                    dd_nav_link_text
                    dd_nav_link_title
                    dd_parent_clickable
                    dd_nav_link_url {
                      url
                      link_type
                      target
                    }
                  }
                  slice_label
                  slice_type
                }
              }
              nav_prismic_name
            }
          }
        }
      }
      prismicFooter {
        id
        data {
          body {
            ... on PrismicFooterBodyLogoColumn {
              id
              primary {
                fc_logo_img {
                  url
                }
              }
              slice_label
              slice_type
            }
            ... on PrismicFooterBodyNavColumn {
              id
              slice_label
              slice_type
              primary {
                fc_nav_resource {
                  uid
                }
              }
            }
            ... on PrismicFooterBodyTextColumn {
              id
              slice_label
              slice_type
              primary {
                fc_text_content {
                  html
                }
              }
            }
          }
          footer_copyright
          footer_prismic_name
          footer_social_links {
            footer_social_network
            footer_social_url {
              raw
            }
          }
        }
      }
    }
  `);
  const source = data.prismicFooter.data;
  const uid = source.body.find((item) => item.slice_type === 'nav_column')
    .primary.fc_nav_resource.uid;
  const navbarData = data.allPrismicNavigation.edges.find(
    (item) => item.node.uid === uid
  ).node.data.body;

  return (
    <footer id="page-footer" className="page-footer">
      <div className="container page-footer-container">
        <div id="page-footer-nav-area" className="row page-footer-row">
          {source.body
            .filter((item) => item.slice_type !== 'nav_column')
            .map((item, idx) => {
              const res = (() => {
                switch (item.slice_type) {
                  case 'logo_column':
                    const url = item.primary.fc_logo_img.url;
                    return url ? (
                      <img src={url} alt="SDPEBA Logo" />
                    ) : (
                      <img src={logo} alt="SDPEBA Logo" height="72" />
                    );

                  case 'text_column':
                    return parse(item.primary.fc_text_content.html);

                  default:
                }
              })();

              return (
                <div className="page-footer-column" key={idx}>
                  {res}
                </div>
              );
            })}
          <div id="page-footer-links" className="page-footer-column">
            <ul>
              {navbarData.map((item, idx) => (
                <li key={idx}>
                  <PrismicLink
                    field={item.primary.single_nav_link_url}
                    className={idx === 0 ? 'active' : ''}
                    title={item.primary.single_nav_link_title}
                  >
                    {item.primary.single_nav_link_text}
                  </PrismicLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div id="page-footer-bottom-bar" className="row page-footer-row">
          <div className="page-footer-column">
            &copy; {new Date().getFullYear()} {source.footer_copyright}
          </div>
          <div className="page-footer-column">
            {source.footer_social_links.map((item, idx) => (
              <PrismicLink
                field={item.footer_social_url.raw}
                className="me-4"
                key={idx}
              >
                {item.footer_social_network}
              </PrismicLink>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default PageFooter;
