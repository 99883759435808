import React from 'react';

const PageHero = ({ slice }) => {
  const theme = 1;

  if (theme === 1) {
    return (
      <div id="page-hero" className="page-hero page-hero--s1">
        <div className="page-hero-image">
          <img src={slice.primary.ph_bg_image.url} alt="Page Hero" />
        </div>
        <div className="page-hero-overlay" />
        <div className="page-hero-body">
          <div className="container">
            <h1 className="page-hero-heading">{slice.primary.ph_headline}</h1>
            <p className="page-hero-text">{slice.primary.ph_text}</p>
          </div>
        </div>
      </div>
    );
  } else return null
}

export default PageHero;
