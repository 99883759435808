// Import React
// Import GraphQL and its Hook for Gatsby
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
// Import the company logo
import logo from '../images/Explorations.png';
import { navigate } from 'gatsby';
import { PrismicLink } from '@prismicio/react';

/**
 * Navbar Component
 */

const Navbar = (props) => {
  const uid = props.main_navbar.uid;
  const position = props.navbar_benefits_position;
  const [source, setSource] = useState([]);
  const data = useStaticQuery(graphql`
    query NavigationQuery {
      allPrismicNavigation {
        edges {
          node {
            id
            uid
            data {
              body {
                ... on PrismicNavigationBodySingleNavLink {
                  id
                  primary {
                    single_nav_link_text
                    single_nav_link_title
                    single_nav_link_url {
                      url
                      link_type
                      target
                    }
                  }
                  slice_type
                  slice_label
                }
                ... on PrismicNavigationBodyDropdownNavLink {
                  id
                  items {
                    dd_group_link_text
                    dd_group_link_title
                    dd_group_link_url {
                      url
                      link_type
                      target
                    }
                  }
                  primary {
                    dd_nav_link_text
                    dd_nav_link_title
                    dd_parent_clickable
                    dd_nav_link_url {
                      url
                      link_type
                      target
                    }
                  }
                  slice_label
                  slice_type
                }
              }
            }
          }
        }
      }
      allPrismicBenefit {
        edges {
          node {
            id
            uid
            data {
              benefit_name
              benefit_type
              benefit_provider {
                uid
              }
            }
          }
        }
      }
      allPrismicProviders {
        edges {
          node {
            id
            uid
            data {
              provider_name
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const benefitTypes = ['retiree', 'active'];
    const navbarResults = [
      ...data.allPrismicNavigation.edges.find((item) => item.node.uid === uid)
        .node.data.body
    ];
    const providerData = data.allPrismicProviders.edges.map((item) => {
      const node = item.node;
      return { uid: node.uid, name: node.data.provider_name };
    });

    benefitTypes.forEach((type) => {
      const temp = data.allPrismicBenefit.edges.filter(
        (item) => item.node.data.benefit_type === type
      );
      let results = [];

      providerData.forEach((provider) => {
        const filterData = temp.filter(
          (d) => provider.uid === d.node.data.benefit_provider.uid
        );
        filterData.length > 0 &&
          results.push({
            name: provider.name,
            uid: provider.uid,
            data: filterData
          });
      });
      navbarResults.splice(position, 0, {
        slice_type: type,
        data: results.reverse()
      });
    });

    setSource(navbarResults);
  }, [data, uid, position]);

  const handleSearch = (event) => {
    const str = event.target.value;
    if (str && event.keyCode === 13) {
      navigate(`/search?searchString=${str}`);
    }
  };
  return (
    <nav className="navbar navbar-expand-xl navbar-light">
      <div className="container-xl">
        <a className="navbar-brand" href="/">
          <img src={logo} alt="Logo" height="72" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {source.map((item, idx) => {
              const res = (() => {
                switch (item.slice_type) {
                  case 'single_nav_link':
                    return (
                      <li className="nav-item" key={idx}>
                        <PrismicLink
                          className="nav-link"
                          field={item.primary.single_nav_link_url}
                          title={item.primary.single_nav_link_title}
                        >
                          {item.primary.single_nav_link_text}
                        </PrismicLink>
                      </li>
                    );

                  case 'dropdown_nav_link':
                    return (
                      <li className="nav-item dropdown" key={idx}>
                        <PrismicLink
                          className="nav-link dropdown-toggle"
                          field={item.primary.dd_nav_link_url}
                          title={item.primary.dd_nav_link_title}
                        >
                          {item.primary.dd_nav_link_text}
                        </PrismicLink>
                        <ul
                          className="dropdown-menu top-level"
                          aria-labelledby={`navbarDropdown-${idx}`}
                        >
                          {item.items.map((nav, index) => (
                            <li key={index}>
                              <PrismicLink
                                className="dropdown-item"
                                field={nav.dd_group_link_url}
                                title={nav.dd_group_link_title}
                              >
                                {nav.dd_group_link_text}
                              </PrismicLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                    );

                  case 'active':
                  case 'retiree':
                    return (
                      <li className="nav-item dropdown" key={idx}>
                        <a
                          className="nav-link dropdown-toggle"
                          href={`/archive/benefits/${item.slice_type}`}
                        >
                          {item.slice_type.charAt(0).toUpperCase() +
                            item.slice_type.slice(1)}{' '}
                          Benefits
                        </a>
                        <ul
                          className="dropdown-menu top-level multi-level"
                          aria-labelledby={`navbarDropdown-${idx}`}
                        >
                          {item.data.map((data, index) => (
                            <li className="dropdown-submenu" key={index}>
                              <a
                                className="dropdown-item"
                                href={`/archive/benefits/${item.slice_type}/${data.uid}`}
                                id={`${data.uid}Dropdown`}
                                role="button"
                                aria-expanded="false"
                              >
                                {data.name}
                              </a>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby={`${data.uid}Dropdown`}
                              >
                                <li>
                                  {data.data.map((d, num) => (
                                    <a
                                      key={num}
                                      className="dropdown-item"
                                      href={`/benefits/${item.slice_type}/${data.uid}/${d.node.uid}`}
                                    >
                                      {d.node.data.benefit_name}
                                    </a>
                                  ))}
                                </li>
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </li>
                    );

                  default:
                }
              })();

              return res;
            })}
          </ul>
          <div className="flex-shirink-0 d-flex flex-nowrap">
            <input
              className="form-control me-2"
              type="text"
              placeholder="Search"
              aria-label="Search"
              onKeyDown={handleSearch}
            />
          </div>
          <PrismicLink
            field={props.navbar_cta_btn_url}
            className="btn btn-secondary"
          >
            {props.navbar_cta_btn_text}
          </PrismicLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
