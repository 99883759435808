import * as React from 'react';
import { PrismicRichText } from '@prismicio/react';

const ServicesList = ({ slice }) => {
  return (
    <>
      <h2 className="display-5 text-center py-5">
        {slice.primary.wwd_headline}
      </h2>
      <div id="services-list" className="services-list">
        <div className="container services-list-container">
          <div className="row services-list-row">
            {slice.items.map((item, idx) => (
              <div className="service-wrapper" key={idx}>
                <div className="service">
                  <h3>{item.wwd_block_heading}</h3>
                  <PrismicRichText field={item.wwd_block_body.raw} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesList;
