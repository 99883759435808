// Import React
import React, { Fragment } from 'react';
// Import component
import PricingPlans from './PricingPlans';

import { PrismicRichText, PrismicLink } from '@prismicio/react';

const BenefitBody = (props) => {
  return (
    <div id="benefit-body" className="benefit-body">
      <div className="container benefit-body-container">
        <div className="row beneift-body-row gx-0 gx-lg-6">
          <div id="benefit-content" className="benefit-content">
            {props.body.map((item, idx) => (
              <Fragment key={idx}>
                {Object.keys(item.primary).includes(
                  'benefit_body_text_content'
                ) ? (
                  <PrismicRichText
                    field={item.primary.benefit_body_text_content.raw}
                  />
                ) : (
                  <>
                    {item.primary.benefit_body_video_embed.raw.map(
                      (video, index) => (
                        <Fragment key={index}>
                          {video && video.oembed && (
                            <div className="ratio ratio-16x9 shadow">
                              <iframe
                                title={
                                  video.oembed.title ? video.oembed.title : ''
                                }
                                loading="lazy"
                                width="640"
                                height="564"
                                frameBorder="0"
                                allowFullScreen="allowfullscreen"
                                src={video.oembed.embed_url}
                              />
                            </div>
                          )}
                        </Fragment>
                      )
                    )}
                  </>
                )}
              </Fragment>
            ))}
          </div>
          <div id="benefit-sidebar" className="benefit-sidebar">
            {props.benefit_pricing_heading &&
              props.benefit_pricing_row.length > 0 && (
                <PricingPlans
                  heading={props.benefit_pricing_heading}
                  items={props.benefit_pricing_row}
                />
              )}
            {props.benefit_action_button_text && (
              <PrismicLink
                field={props.benefit_action_button_link}
                className="btn btn-lg btn-secondary w-100"
                style={{ backgroundColor: props.benefit_action_button_bg }}
              >
                {props.benefit_action_button_text}
              </PrismicLink>
            )}
            <div id="benefit-links" className="mt-4 benefit-links">
              {props.benefit_documents.length > 0 && (
                <h5>Documents &amp; Forms</h5>
              )}
              {props.benefit_documents.map((item, idx) => (
                <Fragment key={idx}>
                  <PrismicLink field={item.benefit_doc_url}>
                    {item.benefit_doc_name}
                  </PrismicLink>
                  <br />
                </Fragment>
              ))}
              <br />
              <h5>Links</h5>
              {props.benefit_links.map((item, idx) => (
                <Fragment key={idx}>
                  <PrismicLink
                    field={item.benefit_link_url}
                    title={item.benefit_link_title}
                  >
                    {item.benefit_link_name}
                  </PrismicLink>
                  <br />
                </Fragment>
              ))}
            </div>
            <div id="benefit-contacts" className="benefit-contacts">
              <h5>Support Numbers</h5>
              <table className="table table-sm">
                <tbody>
                  {props.benefit_support.map((item, idx) => (
                    <tr key={idx}>
                      <td>{item.benefit_support_label}</td>
                      <td>
                        {item.benefit_support_type === 'email' ? (
                          <a href={`mailto:${item.benefit_support_value}`}>
                            {item.benefit_support_value}
                          </a>
                        ) : (
                          item.benefit_support_value
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitBody;
