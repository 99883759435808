import * as React from 'react';

const BenefitHero = ({ company, name, logo, background = 'https://images.unsplash.com/photo-1603696774905-83ae340e2cae?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=3152&q=80' }) => {
  return (
    <div id="benefit-hero" className="benefit-hero">
      <div className="benefit-hero-image">
        <img src={background} alt="benefit-hero" />
      </div>
      <div className="benefit-hero-overlay"></div>
      <div className="container benefit-hero-container">
        <div className="row benefit-hero-row">
          <div className="benefit-hero-body">
            <div className="benefit-hero-company">{company}</div>
            <h1 className="benefit-hero-name">{name}</h1>
          </div>
          <div className="benefit-hero-logo">
            <div className="benefit-logo">
              <img src={logo} alt="benefit-logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BenefitHero;
