// Import React
import React from 'react';
import BenefitCard from './BenefitCard';

/**
 * Benefit List Component
 */
const BenefitList = ({ slice, type, params }) => {
  const filter = params && params.filter ? params.filter : '';
  return (
    <div id="benefits" className="benefits">
      <div className="container benefits-container">
        <div className="row">
          {slice.map((item, idx) => {
            const data =
              type === 'page'
                ? item.bcg_grid_item.document?.data
                : item.node.data;
            const uid =
              type === 'page' ? item.bcg_grid_item.uid : item.node.uid;

            if (
              !data?.benefit_type ||
              (filter && data?.benefit_type !== filter)
            )
              return null;

            return (
              <div className="col-12 col-lg-4 p-4" key={idx}>
                <BenefitCard data={data} uid={uid} type={type} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BenefitList;
