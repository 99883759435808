import * as React from 'react';

const HomeHero = ({ slice }) => {
    return (
        <div id="home-hero" className="home-hero">
            <div className="home-hero-image">
                <img src={slice.primary.home_hero_image.url} alt="home-hero" />
            </div>
            <div className="home-hero-body">
                <div className="container">
                    <p className="home-hero-preheading">{slice.primary.home_hero_preheading}</p>
                    <h1 className="home-hero-heading">{slice.primary.home_hero_heading}</h1>
                </div>
            </div>
        </div>
    );

}

export default HomeHero;
