import React from 'react';
import CTAForm from './CTAForm';

const CallToAction = ({ slice }) => {
  return (
    <div id="cta" className="call-to-action" style={{
      background: `url(${slice.primary.cta_bg_image.url})`,
      backgroundSize: 'cover'
    }}>
      <div className="container">
        <div className="row align-items-center gx-6">
          <div className="col-12 col-lg-6">
            <p className="call-to-action-preheading">{slice.primary.cta_preheadline}</p>
            <h3 className="call-to-action-heading">{slice.primary.cta_headline}</h3>
            <p className="text-white-50">{slice.primary.cta_text}</p>
          </div>
          <div className="col-12 col-lg-6">
            <CTAForm {...slice.primary} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallToAction;
