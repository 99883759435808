// Import React
import * as React from 'react';
import { PrismicRichText } from '@prismicio/react';

/**
 * Benefit Card Component
 */
const BenefitCard = ({ data, uid, type }) => {
  const desciption = data.body.find(
    (item) => item.slice_type === 'benefit_body_text'
  );
  const shortDesc = desciption?.primary?.benefit_body_text_content.raw.reduce(
    (data, item) => {
      if (data.count - item.text.length > 0) {
        data.count -= item.text.length;
        data.raw.push(item);
      } else if (data.count > 0) {
        data.count -= item.text.length;
        data.raw.push({
          ...item,
          text: item.text.substring(0, item.text.length - data.count) + '...'
        });
      }

      return data;
    },
    { raw: [], count: 150 }
  );
  return (
    <div className="card">
      {data.benefit_list_image.url !== null ? (
        <div
          style={{
            backgroundImage: `url(${data.benefit_list_image.url})`,
            height: '250px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}
        />
      ) : (
        // create div with default placeholder gray background and height of 250px
        <div
          style={{
            backgroundImage: `url(https://images.unsplash.com/photo-1551709645-3f16f608bb80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80})`,
            height: '250px'
          }}
        />
        // <div className="card-image" style={{ backgroundColor: "#f5f5f5" }} />
      )}
      <div className="card-body">
        <div>
          <h5 className="card-title me-2">
            {type === 'page'
              ? `${
                  data?.benefit_provider?.document?.data?.provider_name || ''
                } - ${data.benefit_name}`
              : data.benefit_name}
          </h5>
          {data.benefit_type && (
            <span className="badge bg-primary">{data.benefit_type}</span>
          )}
        </div>
        <div className="card-text">
          {shortDesc && <PrismicRichText field={shortDesc.raw} />}
        </div>
        <a
          href={`/benefits/${data.benefit_type}/${data.benefit_provider.uid}/${uid}/`}
          className="btn btn-outline-primary"
        >
          {`Learn More`}
        </a>
      </div>
    </div>
  );
};

export default BenefitCard;
