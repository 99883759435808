import React from 'react';
import BenefitCTAForm from './BenefitCTAForm';

const BenefitCTA = (props) => {
  return (
    <div id="cta" className="call-to-action" style={{
      background: `url(${props.benefit_cta_bg_image.url})`,
      backgroundSize: 'cover'
    }}>
      <div className="container">
        <div className="row align-items-center gx-6">
          <div className="col-12 col-lg-6">
            <p className="call-to-action-preheading">{props.benefit_cta_preheadline}</p>
            <h3 className="call-to-action-heading">{props.benefit_cta_headline}</h3>
            <p className="text-white-50">{props.benefit_cta_text}</p>
          </div>
          <div className="col-12 col-lg-6">
            <BenefitCTAForm {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BenefitCTA;
