import * as React from 'react';

const PricingPlans = ({ heading, items }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th><h5>{heading}</h5></th>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={index}>
            <td>{item.benefit_pricing_plan}</td>
            <td>{item.benefit_pricing_price}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
export default PricingPlans;
