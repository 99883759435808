import React from 'react';

const CTAForm = (props) => {
  return (
    <div className="cta-form">
      <h4 className="cta-form-heading">{props.cta_form_headline}</h4>
      <p>{props.cta_form_text}</p>
      <form action="https://getform.io/f/2a8e620b-39fd-4788-a8a1-d4d23b27a2e6" method="post" className="mt-4">
        <div className="row gy-3">
          <div className="col-xs-12 col-md-6">
            <label htmlFor="uName" className="form-label">Name*</label>
            <input type="text" className="form-control" id="uName" placeholder="e.g. Jane Doe" name="uName" />
          </div>
          <div className="col-xs-12 col-md-6">
            <label htmlFor="uEmail" className="form-label">Email*</label>
            <input type="email" className="form-control" id="uEmail" placeholder="e.g. jane@doe.com" name="uEmail" />
          </div>
          <div className="col-xs-12 col-md-6">
            <label htmlFor="uPhone" className="form-label">Phone</label>
            <input type="tel" className="form-control" id="uPhone" placeholder="e.g. 555-555-5555" name="uPhone" />
          </div>
          <div className="col-xs-12 col-md-6">
            <label htmlFor="uHear" className="form-label">How did you hear about us?</label>
            <input type="text" className="form-control" id="uHear" placeholder="e.g. Google, Phone, Friend" name="uHear" />
          </div>
          <div className="col-xs-12">
            <label htmlFor="uMessage" className="form-label">Message</label>
            <textarea rows="5" className="form-control" id="uMessage" placeholder="What would you like to know?" name="uMessage"></textarea>
          </div>
          <div className="col-xs-12 text-end">
            <button className="btn btn-secondary" type="submit">{props.cta_form_button_text}</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CTAForm;
